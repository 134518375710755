/* Setup */
html {
	height: 100%;
	font-size: 12px;
}

body {
	display: flex;
	flex-direction: column;
	margin: 0;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	min-height: 100%;
	overflow-x: hidden;
}

#root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

/* Overrides */
a {
   text-decoration: none;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}
